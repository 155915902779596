import DashboardCard from "../../../../components/dashboardCard/index";
import HeaderSection from "../../../../components/header-section/index";
import FileInputDashboard from "../../../../components/file-input-dashboard/index";
import { projectsService, profileServices } from "@/core/services";
import Snackbar from "@/components/snackbar/index";
import * as types from "../../../../shared/store/types";
export default {
  name: "profile-setting-employer",
  components: {
    DashboardCard,
    HeaderSection,
    FileInputDashboard,
    Snackbar
  },
  props: [],
  data() {
    return {
      valid: false,
      profileImage: "",
      name: "",
      select: "",
      isDisable: false,
      companyCriteriaItems: [],
      companyTypeLists: [],
      attachments: null,
      titleCard: "پروژه‌ها",
      profileForm: {
        firstName: "",
        lastName: "",
        description: "",
        categoryId: null,
        noOfEmployees: "",
        showSnackbar: false,
        isCompany: null,
        registrationNumber: null,
        skills: ""
      },
      companyName: "",
      snackbarMessage: "لطفا کلیه موارد مشخص شده را کامل نمایید.",
      showSnackbar: false,
      profileRule: {
        firstnameRules: [
          v => !!v.trim() || "لطفا نام خود را وارد کنید",
          v => v.length >= 3 || "نام وارد شده باید بیش از ۳ کاراکتر باشد"
        ],
        lastnameRules: [
          v => !!v.trim() || "نام خانوادگی خود را وارد کنید",
          v =>
            v.length >= 3 || "نام خانوادگی وارد شده باید بیش از ۳ کاراکتر باشد"
        ],
        description: [
          v => !!v.trim() || "لطفا توضیحات را وارد کنید",
          v => v.length >= 10 || "توضیحات وارد شده باید بیش از ۱۰ کاراکتر باشد"
        ]
      },
      skillsList: [],
      skills: [],
      search: null,
      emailRules: [
        v => !!v.trim() || "لطفا ایمیل خود را وارد کنید",
        v => /.+@.+\..+/.test(v) || "ایمیل وارد شده معتبر نیست"
      ],
      skillsInfo: [],
      noOfEmployees: null
    };
  },
  computed: {},
  mounted() {
    this.getCompanyCriteriaItems();
    this.getCategoryId();
    this.getProfileInfo();
    this.getSkillsList();
  },
  methods: {
    updateProfile() {
      this.showSnackbar = false;
      const body = {
        first_name: this.profileForm.firstName
          ? this.profileForm.firstName
          : null,
        last_name: this.profileForm.lastName ? this.profileForm.lastName : null,
        company_name: this.companyName ? this.companyName : null,
        description: this.profileForm.description,
        category_id: this.profileForm.categoryId,
        employees_count: this.profileForm.noOfEmployees
          ? this.profileForm.noOfEmployees
          : null,
        attachment_id: this.attachments,
        skills: this.skills.length > 0 ? this.skills : this.skillsInfo,
        email: this.profileForm.email,
        registration_number: this.profileForm.registrationNumber
          ? this.profileForm.registrationNumber
          : null,
        national_id: null,
        code: null
      };
      console.log(body);
      profileServices.employerUpdateProfile(body).then(res => {
        if (res) {
          this.showSnackbar = true;
          this.snackbarMessage = "پروفایل شما با موفقیت به روز رسانی شد.";
        }
      });
    },
    getSkillsList() {
      projectsService.skills().then(res => {
        this.skillsList = res.data.data;
      });
    },
    getProfileInfo() {
      profileServices.employerGetProfile().then(res => {
        const user = res.data?.data;
        if (user["is_company"] === 1) {
          this.companyName = user.company_name;
        }
        this.profileImage = res.data.data;
        this.profileForm = {
          firstName: user.first_name,
          lastName: user.last_name,
          description: user.description,
          categoryId: user.category_id,
          noOfEmployees: user["employees_count"],
          is_company: user.is_company,
          skills: user.skills,
          phone: user.phone,
          registrationNumber: user.registration_number,
          email: user.email
        };
        user.skills.map(item => {
          this.skillsInfo.push(item.title);
        });
      });
    },
    getCompanyCriteriaItems() {
      projectsService.companyCriteria().then(res => {
        this.companyCriteriaItems = res.data.data["criterias"];
      });
    },
    getCategoryId() {
      projectsService.activityTypes().then(res => {
        this.companyTypeLists = res.data.data;
      });
    },
    getFileId(value) {
      console.log(value);
      this.attachments = value.id;
    },
    hideSnackbar() {
      this.showSnackbar = false;
    },
    selectSkills(list) {
      let tempData = [];
      for (let index = 0; index < list.length; index++) {
        if (list[index].title) {
          tempData.push(list[index].title);
        } else {
          tempData.push(list[index]);
        }
      }
      return (this.skills = tempData);
    },
    enableUpdateProfileButton() {
      this.enableButton = true;
      this.$store.commit(
        types.firstNameAndLastNameManagement.mutations.NAME_MANAGEMENT_MUTATE,
        {
          userName: {
            name:
              this.profileForm.firstName.substring(0, 9) +
              " " +
              this.profileForm.lastName.substring(0, 9)
          }
        }
      );
    }
  },
  watch: {}
};
